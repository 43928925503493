import { downloadServiceUrl } from "../ServiceEndpoints";

interface BulkAvailabilityResponse {
  success: boolean;
}

// Function to poll bulk availability
export const checkBulkAvailability = async (
  requestUuid: string
): Promise<BulkAvailabilityResponse> => {
  const response = await fetch(
    `${downloadServiceUrl}/v1/datasets/bulk/available`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestUuid: requestUuid,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch dataset availability");
  }

  const data: BulkAvailabilityResponse = await response.json();
  return data;
};

// Function to download the bulk
export const downloadBulk = async (requestUuid: string): Promise<Blob> => {
  const response = await fetch(
    `${downloadServiceUrl}/v1/datasets/bulk/download/${requestUuid}`
  );

  if (response.status === 404) {
    throw new Error("Your cart link has expired. Please add new items in your cart and try again");
  }

  if (!response.ok) {
    throw new Error("Failed to download dataset");
  }

  const blob = await response.blob();
  return blob;
};