import React, {ChangeEvent, ChangeEventHandler, useCallback, useEffect, useRef, useState} from "react";
import SearchResults from "../SearchResults/SearchResults";
import {searchForm} from "../../services/SearchService/SearchService";
import {SearchFilter} from "../../model/SearchFilter";
import {SearchResult} from "../../model/SearchResult";
import {TypeOption} from "../../model/TypeOption";
import { Col, Container, Form, InputGroup, OverlayTrigger, Row, Button, ListGroup, Alert, Stack, Collapse, Tooltip } from "react-bootstrap";
import {backendSearchUrl} from "../../services/ServiceEndpoints.ts";
import strings from "../../loc/strings.ts";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {OnChangeValue} from 'react-select';
import {StylesConfig, GroupBase} from 'react-select';
import {VisibilityFilter} from "../../model/VisibilityFilter.ts";
import SearchField from "../SearchField/SearchField.tsx";
import {useTranslation} from "react-i18next";
import {
    SEARCH_FILTERS_TEXT,
    SEARCH_HEADER_TEXT,
    SEARCH_BUTTON_TEXT,
    SEARCH_PLACEHOLDER_TEXT,
    SEARCH_TOOLTIP_TEXT,
    SEARCH_CLEAR_BUTTON_TEXT,
    SEARCH_MORE_FILTERS_TEXT,
    FILTER_KEY_MAPPING,
    DYNAMIC_ALERT_KEYS,
    IMPORTANT_ANNOUNCEMENTS_LABEL, IMPORTANT_ANNOUNCEMENTS_TEXT, SEARCH_NO_RESULTS, Roles
} from "../../constants/EsapConstants.ts";
import {fetchWrapper} from "../../services/FetchWrapperService/FetchWrapper.ts";
import {useUserInfo} from "../../hooks/useUserInfo.ts";
import {UserInfo} from "../../model/UserInfo.ts";

export interface SearchState {
    searchFormFields: SearchFilter
}

interface DatePickers {
    [key: string]: React.RefObject<DatePicker>;
}

export interface CustomStylesOptionType {
    value: string;
    label: string;
}

const customStyles: StylesConfig<CustomStylesOptionType, true, GroupBase<CustomStylesOptionType>> = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#809bcf' : '#ced4da',
        boxShadow: 'none',
        minHeight: 'calc(1.5em + .75rem + 2px)',
        height: 'calc(1.5em + .75rem + 2px)',
        borderRadius: '0 .375rem .375rem 0',
        fontSize: '1rem',
        lineHeight: '1.5',
        color: '#495057',
        backgroundColor: '#fff',
        padding: 0,
        cursor: 'default',
        '&:hover': {
            borderColor: state.isFocused ? '#809bcf' : '#ced4da',
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0 .75rem',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#e9ecef',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#495057',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#495057',
        ':hover': {
            backgroundColor: '#ced4da',
            color: '#212529',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#6c757d',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        color: '#495057',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0 0.375rem',
        color: '#2f2f35',
        border: 'none',
        svg: {
            width: '16px',
            height: '16px',
        },
        '&:hover': {
            color: '#2f2f35',
        }
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '0',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        borderRadius: '.375rem',
        border: '1px solid #ced4da',
        boxShadow: 'none',
        marginTop: '0.125rem',
    }),
    menuList: (provided) => ({
        ...provided,
        padding: '0',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#0d6efd' : '#fff',
        color: state.isSelected ? '#fff' : '#495057',
        padding: '.375rem .75rem',
        borderRadius: '.375rem',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: '#0d6efd',
            color: '#fff',
            borderRadius: '0',
        },
        '&:first-of-type': {
            borderTopLeftRadius: '.375rem',
            borderTopRightRadius: '.375rem',
        },
        '&:last-of-type': {
            borderBottomLeftRadius: '.375rem',
            borderBottomRightRadius: '.375rem',
        },
    }),
};

const Search: React.FC = () => {
    const {t} = useTranslation();
    const {user} = useUserInfo();
    const [userInfo, setUserInfo] = useState<UserInfo | null>();

    const translateFilterKey = (key: string): string => {
        return FILTER_KEY_MAPPING[key] || key;
    };

    useEffect(() => {

        setUserInfo(user);

    }, [user, setUserInfo]);
    const hasRoles = (roles: string[]) => {
        return userInfo?.roles.some((r) => roles.includes(r));
    };

    const [showResults, setShowResults] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [fieldConfigurations, setFieldConfigurations] = useState<VisibilityFilter[]>(
        [{
            category: {
                categoryName: ""
            },
            displayOrder: 0,
            fieldLabel: "",
            fieldName: "",
            fieldType: "",
            group: {
                groupName: ""
            },
            isMulti: false,
            options: [],
            fullWidth: false
        }]
    );
    const [searchFieldsState, setSearchFieldsState] = useState<SearchState>({
        searchFormFields: {
            freeText: "",
            typeOfInformation: [],
            entityLEI: "",
            entityName: "",
            sizeOfEntity: "",
            submissionDateFrom: "",
            submissionDateTo: "",
            dateOrPeriodCoveredFrom: "",
            dateOrPeriodCoveredTo: "",
            submittingEntityName: "",
            submittingEntityLEI: "",
            sizeOfSubmittingEntity: [],
            leiStatus: [],
            countryOfRegisteredOffice: [],
            industrySector: [],
            originalLanguage: [],
            legalFramework: [],
            collectionBodyName: [],
            homeMemberStateOfEntity: [],
            hostMemberStateOfEntity: [],
            isin: "",
            fisn: "",
            voluntaryInfoFlag: false,
            historicalInfoFlag: false
        }
    })
    const [formFieldsState, setFormFieldsState] = useState<SearchState>({
        searchFormFields: {
            freeText: "",
            typeOfInformation: [],
            entityLEI: "",
            entityName: "",
            sizeOfEntity: "",
            submissionDateFrom: "",
            submissionDateTo: "",
            dateOrPeriodCoveredFrom: "",
            dateOrPeriodCoveredTo: "",
            submittingEntityName: "",
            submittingEntityLEI: "",
            sizeOfSubmittingEntity: [],
            leiStatus: [],
            countryOfRegisteredOffice: [],
            industrySector: [],
            originalLanguage: [],
            legalFramework: [],
            collectionBodyName: [],
            homeMemberStateOfEntity: [],
            hostMemberStateOfEntity: [],
            isin: "",
            fisn: "",
            voluntaryInfoFlag: false,
            historicalInfoFlag: false
        }
    })
    const [searchResult, setSearchResult] = useState<SearchResult>({results: [], totalCount: 0});
    const [query, setQuery] = useState<string>("");
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [openSearchFilters, setOpenSearchFilters] = useState(false);
    const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);
    const [resetItemsPerPage, setResetItemsPerPage] = useState<boolean>(false);
    const [sortBy] = useState<string>("relevance");
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [pageNumber] = useState<number>(1);

    const containerRef = useRef<HTMLDivElement | null>(null);

    const datePickers: DatePickers = {
        submissionDateFrom: useRef(null),
        submissionDateTo: useRef(null),
        dateOrPeriodCoveredFrom: useRef(null),
        dateOrPeriodCoveredTo: useRef(null),
    };

    // Filter fields based on category and visibility
    const filteredMainFields = fieldConfigurations.filter(field =>
        field.category.categoryName === strings.categoryNameMain
    );

    const filteredAdditionalFields = fieldConfigurations.filter(field =>
        field.category.categoryName === strings.categoryNameAdditional
    );

    const getFieldValue = (fieldName: keyof SearchFilter): string | boolean | string[] => {
        return formFieldsState.searchFormFields[fieldName] || '';
    };

    const setFieldValue = (fieldName: string, value: string) => {
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                [fieldName]: value
            }
        }));
    };

    const safeStringValue = (fieldName: keyof SearchFilter): string => {
        const value = getFieldValue(fieldName);
        if (Array.isArray(value)) {
            // Assuming you want the first value if it's an array
            return value.length > 0 ? value[0] : '';
        }
        if (typeof value === 'boolean') {
            // Convert boolean to a string representation if needed
            return value ? 'true' : 'false';
        }
        return value;
    };


    useEffect(() => {
        console.log('Fetching field configurations...');
        console.log('Backend URL:', backendSearchUrl);

        const fetchFieldConfigurations = async () => {
            try {
                const url = `${backendSearchUrl}/api/filters/visibility`;
                const response = await fetchWrapper(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data: VisibilityFilter[] = await response.json();
                const mappedData = data.map(field => ({
                    ...field,
                    fieldLabel: translateFilterKey(field.fieldLabel),
                }));


                setFieldConfigurations(mappedData);
                console.log('Field configurations fetched:', data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchFieldConfigurations();
    }, [t]);

    console.log(fieldConfigurations)

    useEffect(() => {
        // Create a style element
        const style = document.createElement('style');
        style.innerHTML = `
            .react-datepicker-popper {
                z-index: 9999 !important;
            }
        `;
        // Append the style element to the document head
        document.head.appendChild(style);

        // Clean up the style element on component unmount
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSuggest = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                freeText: value
            }
        }));
        if (value.length > 2) { // Start suggesting after 3 characters
            try {
                const url = `${backendSearchUrl}/api/autocomplete?query=${value}`;
                const response = await fetchWrapper(url, { method: 'GET' });
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion: string) => {
        setQuery(suggestion); // Update query state with selected suggestion
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                freeText: suggestion // Update freeText in searchFormFields with selected suggestion
            }
        }));
        setSuggestions([]); // Clear suggestions after selection
    };

    const handleClickOutside = (event: MouseEvent) => {
        const container = containerRef.current;
        if (container && !container.contains(event.target as Node)) {
            setSuggestions([]); // Hide suggestions if click outside
        }
    };

    const handleSearchClick = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        // Clear suggestions when the search button is clicked
        setSuggestions([]);

        // Log current state for debugging
        console.log('Current formFieldsState:', formFieldsState);

        // Construct the search payload using values from formFieldsState
        const searchFields = {
            ...formFieldsState.searchFormFields // Use the latest form values
        };

        // Set searchFieldsState to match the current values from formFieldsState
        setSearchFieldsState({searchFormFields: formFieldsState.searchFormFields});
        setItemsPerPage(10);
        setResetItemsPerPage(true);

        console.log('Search payload:', searchFields); // Check payload before sending

        try {
            const data = await searchForm(searchFields, sortBy, itemsPerPage, pageNumber);
            setSearchResult(data);
            console.log('Search result:', data);

            // Use the most recent searchFieldsState after the update
            console.log('Current searchFieldsState:', {...formFieldsState.searchFormFields});
        } catch (error) {
            console.error('Search error:', error);
        } finally {
            setShowResults(true);
            setShowFilters(true);
            setResetItemsPerPage(false);
        }
    };

    const handleClearClick = () => {
        setShowResults(false);
        setShowFilters(false);
        setFormFieldsState({
            searchFormFields: {
                freeText: "",
                typeOfInformation: [],
                entityLEI: "",
                entityName: "",
                sizeOfEntity: "",
                submissionDateFrom: "",
                submissionDateTo: "",
                dateOrPeriodCoveredFrom: "",
                dateOrPeriodCoveredTo: "",
                submittingEntityName: "",
                submittingEntityLEI: "",
                sizeOfSubmittingEntity: [],
                leiStatus: [],
                countryOfRegisteredOffice: [],
                industrySector: [],
                originalLanguage: [],
                legalFramework: [],
                collectionBodyName: [],
                homeMemberStateOfEntity: [],
                hostMemberStateOfEntity: [],
                isin: "",
                fisn: "",
                voluntaryInfoFlag: false,
                historicalInfoFlag: false
            }
        });
        setQuery("")
        setSuggestions([]);
    }

    const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> = (e) => {
        const {name, value} = e.target;
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                [name]: value
            }
        }));
        console.log('Current formFieldsState:', {
            ...formFieldsState.searchFormFields,
            [name]: value
        });
    };

    const getCheckboxValue = (value: boolean | string | string[]): boolean => {
        return typeof value === 'boolean' ? value : false;
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {id, checked} = event.target;

        // Update state for formFieldsState
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                [id]: checked
            }
        }));
    };

    const handleMultiSelectChange = (selectedOptions: OnChangeValue<TypeOption, true> | null, fieldName: string) => {
        // Check if selectedOptions is not null
        const options = selectedOptions ? (selectedOptions as TypeOption[]) : [];
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                [fieldName]: options.map(option => option.value)
            }
        }));
    };

    const renderTooltip = (message: string) => (
        <Tooltip id="button-tooltip">{message}</Tooltip>
    );

    const handleRemoveFilter = (key: keyof SearchFilter) => {
        setFormFieldsState(prevState => {
            // Create a new filter state with the specified key updated
            const updatedFilters = {...prevState.searchFormFields};

            // Special handling for multi-selection fields
            if (
                key === strings.typeOfInformation ||
                key === strings.sizeOfSubmittingEntity ||
                key === strings.leiStatus ||
                key === strings.countryOfRegisteredOffice ||
                key === strings.industrySector ||
                key === strings.originalLanguage ||
                key === strings.legalFramework ||
                key === strings.collectionBodyName ||
                key === strings.homeMemberStateOfEntity ||
                key === strings.hostMemberStateOfEntity
            ) {
                updatedFilters[key] = []; // Clear the array for multi-selection filters
            } else if (key === strings.freeText) {
                setQuery("");
                updatedFilters[key] = ""; // Ensure the key is set to an empty string
            } else {
                updatedFilters[key] = ""; // Set the filter value to an empty string
            }

            // Return the updated state
            return {
                ...prevState,
                searchFormFields: updatedFilters
            };
        });

        // Additional logic to ensure alerts are removed from UI
        setSearchFieldsState(prevState => ({
            searchFormFields: {
                ...prevState.searchFormFields,
                [key]: '' // Ensure the UI updates to reflect the alert removal
            }
        }));
    };

    const handleDateChange = (date: Date | null, fieldName: string) => {
        // Format the date as YYYY-MM-DD if date is valid
        const formattedDate = date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0] : '';

        if (fieldName === strings.submissionDateFrom) {
            if (date && formFieldsState.searchFormFields.submissionDateTo) {
                const submissionToDate = new Date(formFieldsState.searchFormFields.submissionDateTo);
                if (date > submissionToDate) {
                    return;
                }
            }
            setFieldValue(strings.submissionDateFrom, formattedDate);
            if (date && formFieldsState.searchFormFields.submissionDateTo) {
                const submissionToDate = new Date(formFieldsState.searchFormFields.submissionDateTo);
                if (submissionToDate < date) {
                    setFieldValue(strings.submissionDateTo, '');
                }
            }
        } else if (fieldName === strings.submissionDateTo) {
            if (date && formFieldsState.searchFormFields.submissionDateFrom) {
                const submissionFromDate = new Date(formFieldsState.searchFormFields.submissionDateFrom);
                if (date < submissionFromDate) {
                    return;
                }
            }
            setFieldValue(strings.submissionDateTo, formattedDate);
        } else if (fieldName === strings.dateOrPeriodCoveredFrom) {
            setFieldValue(strings.dateOrPeriodCoveredFrom, formattedDate);
            if (date && formFieldsState.searchFormFields.dateOrPeriodCoveredTo) {
                const dateOrPeriodCoveredTo = new Date(formFieldsState.searchFormFields.dateOrPeriodCoveredTo);
                if (dateOrPeriodCoveredTo < date) {
                    setFieldValue(strings.dateOrPeriodCoveredTo, '');
                }
            }
        } else if (fieldName === strings.dateOrPeriodCoveredTo) {
            if (date && formFieldsState.searchFormFields.dateOrPeriodCoveredFrom) {
                const dateOrPeriodCoveredFrom = new Date(formFieldsState.searchFormFields.dateOrPeriodCoveredFrom);
                if (date < dateOrPeriodCoveredFrom) {
                    return;
                }
            }
            setFieldValue(strings.dateOrPeriodCoveredTo, formattedDate);
        }
    };

    const getOptionsForField = useCallback((fieldName: string): TypeOption[] => {
        // Transform fieldConfigurations into the required format
        const visibilityFilter = fieldConfigurations.find(visibilityFilter => visibilityFilter.fieldName === fieldName);
        return visibilityFilter && visibilityFilter.options ? visibilityFilter.options.map(option => ({
            value: option.value,
            label: option.label
        })) : [];
    }, [fieldConfigurations]);

    const getLabelForValue = (fieldName: string, value: string): string => {
        const options = getOptionsForField(fieldName);
        const option = options.find(option => option.value === value);
        return option ? option.label : value; // Return the label if found, otherwise return the raw value
    };

    const handleCloseAlert = () => setShowAlert(false);

    return (
        <Stack>
            <div className="Intro py-4 py-lg-5">
                <Container>
                    <Row>
                        <Col>
                            <h1 className="fs-4 text-center">
                                European Single Access Point
                                <br/>
                                <small>
                                    {t(SEARCH_HEADER_TEXT)}
                                </small>
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Form className="bg-lighter py-5">
                <Container ref={containerRef}>
                    <Row>
                        <Col xs={12}>
                            <>
                                {showAlert && (
                                    <Alert variant="info" className="alert alert-info mt-1 mb-5 alert-dismissible fade show" onClose={handleCloseAlert} dismissible>
                                        <strong>{t(IMPORTANT_ANNOUNCEMENTS_LABEL)}</strong> {t(IMPORTANT_ANNOUNCEMENTS_TEXT)}
                                    </Alert>
                                )}
                            </>
                            <InputGroup className="mb-3">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip(t(SEARCH_TOOLTIP_TEXT))}
                                >
                                    <Button variant="link" className="btn-sm border-end FormHelp">
                                        <i className="bi bi-info-lg" aria-hidden="true"></i>
                                        <span className="visually-hidden">Help with this field</span>
                                    </Button>
                                </OverlayTrigger>
                                <Form.Control
                                    type="text"
                                    placeholder={t(SEARCH_PLACEHOLDER_TEXT)}
                                    aria-label="Free-text search"
                                    name={strings.freeText}
                                    value={query}
                                    onChange={handleSuggest}
                                />

                            </InputGroup>
                            {suggestions.length > 0 && (
                                <ListGroup>
                                    {suggestions.map((suggestion, index) => (
                                        <ListGroup.Item
                                            key={index}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                            className="suggestion-item"
                                        >
                                            {suggestion}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )}
                        </Col>
                    </Row>

                    {showFilters && (
                        <Row aria-label="List of applied filters">
                            <Col xs={12}>
                                <Stack direction="horizontal" gap={2}>
                                    {/* Static Alert for Date Filters */}
                                    <Alert className="SRTag d-none" variant="light">
                                        <Alert.Heading className="SRTagInfo">
                                            <small className="SRTagLabel">{strings.labels.submissionDateLabel}:</small>
                                            <span className="SRTagValue">01/01/2024 - 31/01/2024</span>
                                        </Alert.Heading>
                                        <Button className="btn-light" size="sm" variant="light" data-bs-dismiss="alert" aria-label="Close">
                                            <i className="bi bi-trash3" aria-hidden="true"></i>
                                        </Button>
                                    </Alert>

                                    {/* Conditional Dynamic Alerts */}
                                    {Object.entries(searchFieldsState.searchFormFields).map(([key, value]) => {
                                        // Skip filters with empty values and handle special cases
                                        if (
                                            !value ||
                                            (key === strings.submissionDateFrom || key === strings.submissionDateTo ||
                                                key === strings.dateOrPeriodCoveredFrom || key === strings.dateOrPeriodCoveredTo)
                                        ) {
                                            return null;
                                        }

                                        // Special handling for multi-selection fields
                                        if ((
                                            key === strings.typeOfInformation ||
                                            key === strings.sizeOfSubmittingEntity ||
                                            key === strings.leiStatus ||
                                            key === strings.countryOfRegisteredOffice ||
                                            key === strings.industrySector ||
                                            key === strings.originalLanguage ||
                                            key === strings.legalFramework ||
                                            key === strings.collectionBodyName ||
                                            key === strings.homeMemberStateOfEntity ||
                                            key === strings.hostMemberStateOfEntity
                                        ) && Array.isArray(value) && value.length === 0) {
                                            return null;
                                        }

                                        // Get the label for the field itself
                                        const fieldLabel = t(DYNAMIC_ALERT_KEYS[key as keyof typeof DYNAMIC_ALERT_KEYS] || key);


                                        return (
                                            <Alert className="SRTag" variant="light" key={key}>
                                                <Alert.Heading className="SRTagInfo">
                                                    <small className="SRTagLabel">{fieldLabel}:</small>
                                                    <span className="SRTagValue">
                                                        {Array.isArray(value)
                                                            ? value.map(v => getLabelForValue(key, v)).join('; ') // Map values to their labels for multi-select
                                                            : (typeof value === "boolean"
                                                                ? value.toString()
                                                                : getLabelForValue(key, value)) // Handle single value fields
                                                        }
                                                    </span>
                                                </Alert.Heading>
                                                <Button className="btn-light" size="sm" variant="light"
                                                        data-bs-dismiss="alert"
                                                        aria-label="Close"
                                                        onClick={() => handleRemoveFilter(key as keyof SearchFilter)}>
                                                    <i className="bi bi-trash3" aria-hidden="true"></i>
                                                </Button>
                                            </Alert>
                                        );
                                    })}

                                    {/* Conditional Date Filters */}
                                    {showFilters && (searchFieldsState.searchFormFields[strings.submissionDateFrom] ||
                                        searchFieldsState.searchFormFields[strings.submissionDateTo] ||
                                        searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredFrom] ||
                                        searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredTo]) && (
                                        <>
                                            {/* Submission Date Alert */}
                                            {(searchFieldsState.searchFormFields[strings.submissionDateFrom] ||
                                                searchFieldsState.searchFormFields[strings.submissionDateTo]) && (
                                                <Alert className="SRTag" variant="light" key={strings.submissionDate}>
                                                    <Alert.Heading className="SRTagInfo">
                                                        <small className="SRTagLabel">{strings.labels.submissionDateLabel}:</small>
                                                        <span className="SRTagValue">
                                                            {`${searchFieldsState.searchFormFields[strings.submissionDateFrom] || ''} - ${searchFieldsState.searchFormFields[strings.submissionDateTo] || ''}`}
                                                        </span>
                                                    </Alert.Heading>
                                                    <Button className="btn-light" size="sm" variant="light"
                                                            data-bs-dismiss="alert"
                                                            aria-label="Close" onClick={() => {
                                                        handleRemoveFilter(strings.submissionDateFrom);
                                                        handleRemoveFilter(strings.submissionDateTo);
                                                    }}>
                                                        <i className="bi bi-trash3" aria-hidden="true"></i>
                                                    </Button>
                                                </Alert>
                                            )}

                                            {/* Date Covered Alert */}
                                            {(searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredFrom] ||
                                                searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredTo]) && (
                                                <Alert className="SRTag" variant="light" key={strings.dateOrPeriodCovered}>
                                                    <Alert.Heading className="SRTagInfo">
                                                        <small className="SRTagLabel">{strings.labels.dateOrPeriodCoveredLabel}:</small>
                                                        <span className="SRTagValue">
                                                            {`${searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredFrom] || ''} - ${searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredTo] || ''}`}
                                                        </span>
                                                    </Alert.Heading>
                                                    <Button className="btn-light" size="sm" variant="light"
                                                            data-bs-dismiss="alert"
                                                            aria-label="Close" onClick={() => {
                                                        handleRemoveFilter(strings.dateOrPeriodCoveredFrom);
                                                        handleRemoveFilter(strings.dateOrPeriodCoveredTo);
                                                    }}>
                                                        <i className="bi bi-trash3" aria-hidden="true"></i>
                                                    </Button>
                                                </Alert>
                                            )}
                                        </>
                                    )}
                                </Stack>
                            </Col>
                        </Row>
                    )}


                    <Row>
                        <>
                            <Col>
                                <Button
                                    variant="link"
                                    disabled={!hasRoles([Roles.SEARCH_ENGINE_ROLE])}
                                    onClick={() => setOpenSearchFilters(!openSearchFilters)}
                                    className={`ExpColl ms-1 ${openSearchFilters ? '' : 'collapsed'}`}
                                    aria-expanded={openSearchFilters}
                                    aria-controls="SearchFilters"
                                >
                                    {t(SEARCH_FILTERS_TEXT)}
                                </Button>
                            </Col>

                            <Collapse in={openSearchFilters}>
                                <Col xs={12} className="py-3" id="SearchFilters">
                                    <Row>
                                        <SearchField
                                            fields={filteredMainFields}
                                            formFieldsState={formFieldsState}
                                            handleInputChange={handleInputChange}
                                            handleMultiSelectChange={handleMultiSelectChange}
                                            handleDateChange={handleDateChange}
                                            datePickers={datePickers}
                                            getOptionsForField={getOptionsForField}
                                            safeStringValue={safeStringValue}
                                            renderTooltip={renderTooltip}
                                            customStyles={customStyles}
                                            handleCheckboxChange={handleCheckboxChange}
                                            getCheckboxValue={getCheckboxValue}
                                        />
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                variant="link"
                                                className={`ExpColl ms-1 ${openAdditionalFilters ? '' : 'collapsed'}`}
                                                onClick={() => setOpenAdditionalFilters(!openAdditionalFilters)}
                                                aria-expanded={openAdditionalFilters}
                                                aria-controls="SearchAdditionalFilters"
                                            >
                                                {t(SEARCH_MORE_FILTERS_TEXT)}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Collapse in={openAdditionalFilters}>
                                        <Row id="SearchAdditionalFilters" className="py-3">
                                            <Col xs={12} className="mb-3">
                                                <Row>
                                                    <SearchField
                                                        fields={filteredAdditionalFields}
                                                        formFieldsState={formFieldsState}
                                                        handleInputChange={handleInputChange}
                                                        handleMultiSelectChange={handleMultiSelectChange}
                                                        handleDateChange={handleDateChange}
                                                        datePickers={datePickers}
                                                        getOptionsForField={getOptionsForField}
                                                        safeStringValue={safeStringValue}
                                                        renderTooltip={renderTooltip}
                                                        customStyles={customStyles}
                                                        handleCheckboxChange={handleCheckboxChange}
                                                        getCheckboxValue={getCheckboxValue}
                                                    />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </Col>
                            </Collapse>
                        </>

                        <Col className="d-flex justify-content-end mt-5 mt-lg-0">
                            <Button
                                type="submit"
                                disabled={!hasRoles([Roles.SEARCH_ENGINE_ROLE])}
                                variant="primary"
                                onClick={handleSearchClick}
                                className="me-2"
                            >
                                <i className="bi bi-search me-2"></i> {t(SEARCH_BUTTON_TEXT)}
                            </Button>
                            <Button
                                type="reset"
                                disabled={!hasRoles([Roles.SEARCH_ENGINE_ROLE])}
                                variant="link"
                                className="link-dark ms-3"
                                onClick={handleClearClick}
                            >
                                <i className="bi bi-arrow-clockwise me-2"></i> {t(SEARCH_CLEAR_BUTTON_TEXT)}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
            {showResults && (
                searchResult.results.length > 0 ? (
                    <SearchResults
                        initialSearchResults={searchResult}
                        searchFields={searchFieldsState.searchFormFields}
                        resetItemsPerPage={resetItemsPerPage}
                    />
                ) : (
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={6} className="mx-auto">
                                <div className="text-center">{t(SEARCH_NO_RESULTS)}</div>
                            </Col>
                        </Row>
                    </Container>
                )
            )}
        </Stack>
    )
}

export default Search;