import { useState } from 'react';
import { CartItem } from '../../model/CartItem';
const LOCAL_STORAGE_KEY = 'cartItems';

const useCart = () => {
    const [cartItems, setCartItems] = useState<CartItem[]>(() => {
        const storedCartItems = localStorage.getItem(LOCAL_STORAGE_KEY);
        return storedCartItems ? JSON.parse(storedCartItems) : [];
    });

    // Add an item to the cart
    const addCartItem = (item: CartItem) => {
        const updatedItems = [...cartItems, item];
        setCartItems(updatedItems);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedItems));
    };

    // Delete an item from the cart
    const deleteCartItem = (datasetId: string) => {
        const updatedItems = cartItems.filter(item => item.datasetId !== datasetId);
        setCartItems(updatedItems);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedItems));
    };

    // Get all items in the cart
    const getCartItems = () => {
        return cartItems;
    };

    const deleteAllFromCart = () => {
        const updatedItems: CartItem[] = [];
        setCartItems(updatedItems);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedItems));
    };

    return {
        cartItems,
        addCartItem,
        deleteCartItem,
        getCartItems,
        deleteAllFromCart
    };
};

export default useCart;
