import {WebStorageStateStore} from "oidc-client-ts";
import {AuthProviderProps} from "react-oidc-context";

const tenantId:string = import.meta.env.VITE_REACT_APP_TENANTID == null ? '' : import.meta.env.VITE_REACT_APP_TENANTID;

const clientId:string = import.meta.env.VITE_REACT_APP_CLIENTID == null ? '' : import.meta.env.VITE_REACT_APP_CLIENTID;

const redirectUrl:string = import.meta.env.VITE_REACT_APP_REDIRECTURI == null ? '' : import.meta.env.VITE_REACT_APP_REDIRECTURI;

const oidcConfig: AuthProviderProps = {
  authority: 'https://login.microsoftonline.com/' + tenantId + '/v2.0/',
  client_id: clientId,
  redirect_uri: redirectUrl,
  response_type: 'code',
  // scope: 'openid profile User.Read api://080f57ff-8a7e-4b7c-a005-8fcbc1dc0519/API.All',
  // scope: 'openid api://080f57ff-8a7e-4b7c-a005-8fcbc1dc0519/API.All',
  scope: 'openid profile User.Read',
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({store: window.localStorage}),
  // loadUserInfo: false,
  loadUserInfo: true,
};

export default oidcConfig;