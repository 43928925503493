import {useAuth} from "react-oidc-context";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Callback = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        const handleCallback = async () => {
            try {
                if (auth.isLoading) {
                    return <div>Loading...</div>;
                }

                if (auth.user && auth.user.id_token) {
                    navigate("/search");
                }
            } catch (error) {
                console.error('Login callback error: ', error);
                navigate("/notFound")
            }
        };

        handleCallback();
    }, [auth.user, auth.isLoading, navigate]);

    return (
        <div>
            <p>Logging in...</p>
        </div>
    );
};

export default Callback;