import { useState, useRef } from "react";
import useCart from "../../services/CartSevice/CartService.ts";
import { bulkDownloadRequestService } from "../../services/DatasetDetailsService/DatasetDetailsService.ts";
import { checkBulkAvailability, downloadBulk } from "../../services/CartSevice/BulkDownloadService.ts";
import { Col, Container, Row, Button, Alert, Spinner, Modal } from "react-bootstrap";

const Cart: React.FC = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { cartItems, deleteCartItem, deleteAllFromCart } = useCart();
  const [requestUuid, setRequestUuid] = useState<string | null>(null);
  const [polling, setPolling] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [bulkAvailableMsg, setBulkAvailableMsg] = useState<string | null>(null);
  const [generated, setGenerated] = useState<boolean>(false);
  const [downloadEnabled, setDownloadEnabled] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Use ref to store interval ID

  const handleRequestDownload = async () => {
    try {
      setGenerated(true);
      // TODO checking for exceeding limits
      if (cartItems.length > 3) {
        setShowAlert(true);
        setGenerated(false);
        return;
      }
      const datasetIds = new Set(cartItems.map((item) => item.datasetId));
      console.log("Tech Record IDs:", Array.from(datasetIds));
      const response = await bulkDownloadRequestService(datasetIds);
      setRequestUuid(response);
      await startPollingBulkDownload(response);
    } catch (error) {
      let errorMsg = "Unexpected error.";
      if (error instanceof Error) {
        errorMsg = error.message;
      }
      setError(errorMsg);
      setGenerated(false);
    }
  };

  const handleRemoveItem = (techRcrdIdr: string) => {
    deleteCartItem(techRcrdIdr);
    setGenerated(false);
    setDownloadEnabled(false);
  };

  const startPollingBulkDownload = async (requestId: string) => {
    setPolling(true);
    setError(null);
    await pollForBulk(requestId);

    // Poll every 10 seconds
    intervalRef.current = setInterval(() => pollForBulk(requestId), 10000);
  };

  const pollForBulk = async (requestId: string) => {
    try {
      const data = await checkBulkAvailability(requestId);
      const { success } = data;

      if (success) {
        // Stop polling if the requestId is available
        stopPolling();
        setBulkAvailableMsg(null);
        setDownloadEnabled(true);
      } else {
        // Continue the polling if the requestId is NOT available
        setBulkAvailableMsg("Bulk is not ready yet.");
      }
    } catch (err) {
      setError("Error checking bulk availability.");
      stopPolling();
    }
  };

  const stopPolling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear interval to stop polling
    }
    setPolling(false);
    setGenerated(false);
  };

  // Function to download the dataset
  const downloadBulkZip = async (requestId: string) => {
    try {
      setDownloading(true);
      const blob = await downloadBulk(requestId);

      // Handle file download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = bulkZipName();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      deleteAllFromCart();
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(errorMessage);
      setDownloadEnabled(false);
      console.error(err);
    } finally {
      setDownloading(false);
    }
  };

  const bulkZipName = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}.zip`;
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  // Confirm the generation request and close the modal
  const handleConfirmGeneration = () => {
    setShowModal(false);
    handleRequestDownload(); // Proceed with the generation
  };

  return (
    <Container fluid className="d-flex flex-column p-0">
      <Container fluid className="Intro py-2 flex-grow-0">
        <Row className="text-center m-0">
          <Col>
            <h1 className="fs-4">
              <small>My Cart</small>
            </h1>
          </Col>
        </Row>
      </Container>

      <Container fluid="md">
        <Row className="my-5 justify-content-center">
          <Col xs={12} md={10}>
            {showAlert && (
              <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible className="mb-5">
                The number of datasets added to cart exceeds the allowed <b>3</b>.
              </Alert>
            )}
            {bulkAvailableMsg && (
              <Alert variant="warning" className="mb-5">
                {bulkAvailableMsg}
              </Alert>
            )}
            {error && (
              <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible className="mb-5">
                {error}
              </Alert>
            )}

            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <Row key={item.datasetId} className="align-items-center mb-3 border-bottom pb-2">
                  <Col xs={10} className="d-flex align-items-center">
                    {item.RgltryDataTp.map((data, dataIndex) => (
                      <span key={dataIndex} className="text-primary">
                        {[
                          data.Clssfctn,
                          item.RltdNttyLglPrsnOrgMainNm || "",
                          item.RltdNttyLglPrsnLEI,
                          item.RltdPrdToDt ? `${formatDate(item.RltdPrdFrDt)} - ${formatDate(item.RltdPrdToDt)}` : formatDate(item.RltdPrdFrDt),
                        ]
                          .filter(Boolean)
                          .join(" ")}
                      </span>
                    ))}
                  </Col>

                  <Col xs={2} className="text-end">
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => handleRemoveItem(item.datasetId)}
                      disabled={generated || downloading || polling || downloadEnabled}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </Col>
                </Row>
              ))
            ) : (
              <div className="text-center text-primary mt-3">
                <p>Your cart is empty.</p>
              </div>
            )}

            <div className="d-flex justify-content-end mt-5">
              <Button className="me-1" variant="primary" onClick={handleOpenModal} disabled={!cartItems.length || downloadEnabled}>
                {generated && !downloadEnabled ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                    Generating...
                  </>
                ) : (
                  <>
                    <i className="bi me-2" aria-hidden="true"></i> Generate
                  </>
                )}
              </Button>
              <Button
                variant="primary"
                onClick={() => downloadBulkZip(requestUuid!)}
                disabled={!downloadEnabled || downloading || polling || !cartItems.length}
              >
                {downloading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                    Downloading...
                  </>
                ) : (
                  <>
                    <i className="bi bi-download me-2" aria-hidden="true"></i> Download
                  </>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm your Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to generate the zip file including these items?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmGeneration}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Cart;
