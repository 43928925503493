import {DatasetResponse, File} from "../../model/InfoviewerResult";
import {downloadServiceUrl, infoviewerUrl} from "../ServiceEndpoints";
import {fetchWrapper} from "../FetchWrapperService/FetchWrapper.ts";
import { DataFlowTypes } from "../../constants/EsapConstants.ts";

let controller: AbortController | null = null;

export const calculateIfNeedsProcessing = (datasetResponse: DatasetResponse, file: File) => {
    return ((datasetResponse.dataFlowType === DataFlowTypes.DATSSR
                && file.fileType === 'XML'
            ));
}

export async function fetchDatasetFileset(datasetId: string): Promise<DatasetResponse | null> {
    try {
        const url = `${infoviewerUrl}/v1/datasets/${datasetId}`;
        const response = await fetchWrapper(url);
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Request failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export async function fetchFileContents(datasetId: string, fileId: string, fileType: string, needProcessing: boolean): Promise<string | Blob | object | object[] | undefined> {
    try {
        // TODO Use this here for moving load to Storage account.
        //const url = `${infoviewerFilesUrl}/${datasetId}/files/${fileId}`;
        // TODO use similar approach for all file fetches (infoviewer/download service).
        const processTextPart = needProcessing ? '/process' : '';
        const url = `${infoviewerUrl}/v1/datasets${processTextPart}/${datasetId}/files/${fileId}`;
        if (controller) {
            controller.abort();
            console.log("Aborted previous fetch request.") 
           }
           controller = new AbortController();
           const signal = controller.signal;
   
        const response = await fetchWrapper(url,{signal});

        if (!response.ok) {
            if (response.status === 400) {
                const errorText = await response.text();
                throw new Error(`Bad Request: ${errorText}`);
            } else if (response.status === 404) {
                throw new Error(`The file is not available.`);
            } else if (response.status === 500) {
                const errorText = await response.text();
                throw new Error(`Internal Server Error: ${errorText}`);
            } else {
                throw new Error(`Unexpected error: ${response.statusText}`);
            }
        }

        if (response.status === 200) {
            if (fileType == 'XML' && needProcessing) {
                return await response.json();
            }
            switch (fileType) {
                case 'XML':
                case 'XBRL':
                case 'TXT':
                case 'HTML':
                case 'XHTML':
                case 'ESEF_ZIP':
                    return await response.text();
                case 'PDF':
                    return await response.blob();
                case 'CSV':
                case 'JSON':
                    return await response.json();
                default:
                    throw new Error('Unsupported file type: ' + fileType);
            }
        }
    } catch (error) {
        console.error("Error fetching dataset file with id:", fileId);
        throw error;
    } finally {
        controller = null;
    }
}

export async function downloadDataset(datasetId: string): Promise<boolean> {
    try {
        const url = `${downloadServiceUrl}/v1/datasets/${datasetId}`;
        const response = await fetchWrapper(url);

        if (response.ok) { 
            const zipBlob = await response.blob();
            const url = window.URL.createObjectURL(zipBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${datasetId}.zip`;
            document.body.appendChild(a);
            // TODO check alternative.
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            return true;
        } else {
            throw new Error(`Request failed with status ${response.status}`);
        }
    } catch (error) {
        console.error("Download service error: ", error);
        return false;
    }
}

export async function downloadDatasetFile(datasetRealId: string, fileId: string, fileName: string): Promise<boolean> {
    const url = `${downloadServiceUrl}/v1/datasets/${datasetRealId}/files/${fileId}`;

    try {
        const response = await fetchWrapper(url);

        if (!response.ok) {
            if (response.status === 400) {
                const errorText = await response.text();
                throw new Error(`Bad Request: ${errorText}`);
            } else if (response.status === 404) {
                throw new Error(`The file is not available.`);
            } else if (response.status === 500) {
                const errorText = await response.text();
                throw new Error(`Internal Server Error: ${errorText}`);
            } else {
                throw new Error(`Unexpected error: ${response.statusText}`);
            }
        }

        const fileBlob = await response.blob();
        const url_file = window.URL.createObjectURL(fileBlob);
        // TODO Check alternative.
        const a = document.createElement('a');
        a.href = url_file;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url_file);
        return true;
    } catch (error) {
        console.error("Error fetching dataset file with id:", fileId);
        throw error;
    }
}

export async function bulkDownloadRequestService(cartItems: Set<string>): Promise<string> {
    const url = `${downloadServiceUrl}/v1/datasets/bulk/downloadRequest`;


    const itemsArray = Array.from(cartItems);
    const requestBody = JSON.stringify({ items: itemsArray });

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody,
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }


        const responseData = await response.json();
        const uuid = responseData.uuid;
        if (!uuid) {
            throw new Error('Request Uuid not available.');
        }

        return uuid;
    } catch (error) {
        let errorMsg = 'An error occurred while fetching data.'
        if (error instanceof Error) {
            errorMsg = error.message;
        }
        throw new Error(errorMsg);
    }}


