const backendSearchUrl: string = import.meta.env.VITE_REACT_APP_BACKEND_SEARCH_URL || "";
const backendCoreUrl: string = import.meta.env.VITE_REACT_APP_BACKEND_CORE_URL || "";
const infoviewerUrl: string = import.meta.env.VITE_REACT_APP_INFOVIEWERURL || "";
const infoviewerFilesUrl: string = import.meta.env.VITE_REACT_APP_INFOVIEWER_FILES_URL || ""; // TODO Add similar for Download Service.
const downloadServiceUrl: string = import.meta.env.VITE_REACT_APP_DOWNLOADSERVICEURL || "";

const ServiceEndpoints = {
    FetchUserPhoto: "https://graph.microsoft.com/v1.0/me/photo/$value"
}

// Log for debugging
console.log('Import.meta.env:', import.meta.env);
console.log('Backend URL:', backendSearchUrl);
console.log('Backend Azure URL:', backendCoreUrl);

// Export the backendUrl for use in other parts of your application
export { backendSearchUrl, backendCoreUrl, infoviewerUrl, infoviewerFilesUrl, downloadServiceUrl };

export default ServiceEndpoints;
