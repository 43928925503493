import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { downloadDataset, downloadDatasetFile, fetchDatasetFileset, fetchFileContents, calculateIfNeedsProcessing } from '../../services/DatasetDetailsService/DatasetDetailsService';
import { DatasetResponse, File } from '../../model/InfoviewerResult';
import XmlDisplay from './components/XmlDisplay';
import XbrlDisplay from './components/XbrlDisplay';
import PlainTextDisplay from './components/PlainTextDisplay';
import PdfDisplay from './components/PdfDisplay';
import JsonDisplay from './components/JsonDisplay';
import HtmlDisplay from './components/HtmlDisplay';
import EsefZipDisplay from './components/EsefZipDisplay';
import CsvDisplay from './components/CsvDisplay';
import { FileTypes } from '../../model/FileTypes';
import CustomSpinner from './common/Spinner';
import { Spinner, Stack } from 'react-bootstrap';
import useCart from '../../services/CartSevice/CartService';
import { CartItem } from "../../model/CartItem.ts";
import { uuidGeneratorWithTimestamp } from '../../utils/uuidGenerator';
import { useAppInsights } from '../../hooks/useAppInsights';
import { CustomEvents, DataFlowTypes } from '../../constants/EsapConstants';

const DatasetDetails: React.FC = () => {
    const { datasetId } = useParams();
    console.log("datasetId", datasetId);
    const [datasetResponse, setDatasetResponse] = useState<DatasetResponse | null>(null);
    const [error, setError] = useState<string | null>(null);
    const {addCartItem, deleteCartItem, getCartItems} = useCart();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileContent, setFileContent] = useState<string | Blob | object | object[] | null>(null);
    const [contentError, setContentError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
    const [isInCart, setIsInCart] = useState<boolean>(false);
    const appInsights = useAppInsights();
    

    useEffect(() => {
        const fetchAndSetFileContent = async (datasetResponseParam: DatasetResponse | null, file: File) => {
            setLoading(true);
            if(!(datasetResponseParam?.datasetRealId && datasetResponseParam?.dataFlowType)) {
                setLoading(false);
                return;
            }
            const needProcessing: boolean = calculateIfNeedsProcessing(datasetResponseParam, file);
            try {
                const content = await fetchFileContents(datasetResponseParam.datasetRealId, file.fileId, file.fileType, needProcessing);
                if (content) {
                    createCustomFileEvent(CustomEvents.VIEW_FILE, file.fileType);
                    setFileContent(content);
                    setContentError(null);
                }
            } catch (err) {
                if (err instanceof Error) {
                    setContentError(err.message);
                } else {
                    setContentError('An error occurred while fetching the file.');
                }
                setFileContent(null);
            } finally {
                setLoading(false);
            }
        };

        const getDatasetFileset = async (datasetIdParam: string) => {
            const startTime = Date.now();
            const errorMessage = 'Failed to fetch dataset files.';
            if (datasetIdParam) {
                try {
                    const result: DatasetResponse | null = await fetchDatasetFileset(datasetIdParam);
                    console.log(result);
                    setDatasetResponse(result)
                    setError(result != null ? null : errorMessage);
                    // Set the 1st file as the default.
                    if(result) {
                        const endTime = Date.now();
                        createCustomDatasetEvent(CustomEvents.VIEW_DATASET, endTime - startTime, result.datasetRealId, result.dataFlowType);
                        if (result?.files?.length > 0 && result?.files[0]) {
                            setSelectedFile(result.files[0]);
                            fetchAndSetFileContent(result, result.files[0]);
                        }
                    }
                    const isDatasetInCart = getCartItems().some(cartItem => cartItem.datasetId ===datasetIdParam);
                    setIsInCart(isDatasetInCart);

                } catch (err) {
                    setError(errorMessage);
                    console.error(err);
                }
            }
        
        };      

        if(datasetId) {
            // Fetch dataset fileset along with metadata.
            getDatasetFileset(datasetId);
        }
         else {
            console.error("DatasetId was null/undefined");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasetId]);

    const handleCartToggle = (datasetResponse: DatasetResponse) => {
        const isInCart = getCartItems().some(cartItem => cartItem.datasetId === datasetResponse.datasetRealId);

        if (isInCart) {
            deleteCartItem(datasetResponse.datasetRealId);
            setIsInCart(false);
            console.log(`Item with datasetID ${datasetResponse.datasetRealId} removed from the cart.`);
        } else {
                const newCartItem: CartItem = {
                    datasetId: datasetResponse.datasetRealId,
                    RltdPrdFrDt: datasetResponse.RltdPrdFrDt,
                    RltdPrdToDt: datasetResponse.RltdPrdToDt,
                    RltdNttyLglPrsnLEI: datasetResponse.RltdNttyLglPrsnLEI,
                    RltdNttyLglPrsnOrgMainNm: datasetResponse.RltdNttyLglPrsnOrgMainNm,
                    RgltryDataTp: datasetResponse.RgltryDataTp.map((data) => ({
                        Clssfctn: data.Clssfctn,
                        LglFrmwk: data.LglFrmwk
                    }))
                };
                addCartItem(newCartItem);
                setIsInCart(true);
                console.log(`Item with datasetID ${datasetResponse.datasetRealId} added to the cart.`);
            }
        console.log('Current Cart Items:', getCartItems());
    };
    
    const createCustomFileEvent = (name: string, dataFormat: string) => {
        const eventUUID = uuidGeneratorWithTimestamp(); 
        appInsights.trackEvent({name: name}, {eventUUID: eventUUID, dataFormat: dataFormat});     
    }

    const createCustomDatasetEvent = (name: string, responseTime: number, datasetId: string, dataFlowType: string) => {
        const eventUUID = uuidGeneratorWithTimestamp(); 
        // TODO add searchEventUUID, type of information and legal framework properties in event
        appInsights.trackEvent({name: name}, {eventUUID: eventUUID, responseTime: responseTime / 1000, datasetId: datasetId, fileTypeCode: dataFlowType});     
    }
    
    async function handleClick(file: File) {
        setSelectedFile(file);
        setLoading(true);
        if(!(datasetResponse?.datasetRealId && datasetResponse?.dataFlowType)) {
            console.error("DatasetId was null/undefined or had dataFlowType empty.");
            setFileContent(null);
            setLoading(false);
            setContentError(null);
            return;
        }
        try {
            const needProcessing: boolean = calculateIfNeedsProcessing(datasetResponse, file);
            const content = await fetchFileContents(datasetResponse.datasetRealId, file.fileId, file.fileType, needProcessing);
            if (content) {
                createCustomFileEvent(CustomEvents.VIEW_FILE, file.fileType);
                setFileContent(content);
                setContentError(null);
            }
        } catch (err) {
            if (err instanceof Error) {
                setContentError(err.message);
            } else {
                setContentError('An error occurred while fetching the file.');
            }
            setFileContent(null);
        } finally {
            setLoading(false);
        }
    }

    async function handleDownload(datasetId: string, dataFlowType: string) {
        const startTime = Date.now();
        if(datasetId) {
            setIsDownloading(true);
            try {
                if (await downloadDataset(datasetId)) {
                    const endTime = Date.now();
                    createCustomDatasetEvent(CustomEvents.DOWNLOAD_DATASET, endTime - startTime, datasetId, dataFlowType);
                }
            } catch (error) {
                console.error("Download failed", error);
            } finally {
                setIsDownloading(false);
            }
        }
        else {
            console.error("datasetId was empty/null");
        }
    }

    async function handleDatasetFileDownload(datasetId: string, file: File | null) {
        setIsFileDownloading(true);
        setContentError(null);
        try {
            if (await downloadDatasetFile(datasetId, file!.fileId, file!.fileName)) {
                createCustomFileEvent(CustomEvents.DOWNLOAD_FILE, file!.fileType);
            }
        } catch (error) {
            if (error instanceof Error) {
                setContentError(error.message);
            } else {
                setContentError('An error occurred while downloading the file.');
            }
            setFileContent(null);
        } finally {
            setIsFileDownloading(false);
        }
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!datasetResponse) {
        return <div className='d-flex justify-content-center align-items-center'><CustomSpinner /></div>;
    }
   

    return (
        <Stack>
            <div className="DTop TopDivider">
                <div className="container">
                    <div className="row pt-2 pb-1">
                        <div className="col-12 d-flex justify-content-between">
                            <div>
                                <a href="#" className="btn btn-link px-2 px-lg-3"><i className="bi bi-arrow-return-left me-2" aria-hidden="true"></i> Back to results list</a>
                            </div>
                            <div>
                                <a href="#" className="btn btn-link px-2 px-lg-3"><i className="bi bi-chevron-left" aria-hidden="true"></i> Previous</a>
                                <small className="fw-bold">1 / 123</small>
                                <a href="#" className="btn btn-link px-2 px-lg-3">Next <i className="bi bi-chevron-right" aria-hidden="true"></i>	</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Dataset Metadata. */ }
            <div className="DHeader pt-5 pb-4 mx-3 rounded-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="fs-3 mb-4">
                            <span className="fw-normal">[{datasetResponse?.props}]</span>
                                <span className="fw-light">[{datasetResponse?.legalName}]</span>
                                <span className="fw-normal">[{datasetResponse?.certificate}]</span>
                                <span className="fw-light">[{datasetResponse?.date}]</span>
                            </h1>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                            <div className="p-0 d-flex justify-content-between rounded-1 DHInfo">
                                <div className="d-flex justify-content-start">
                                <button
                                      type="button"
                                      className="btn btn-outline-primary border-0 rounded-0 px-2 px-lg-3 d-flex align-items-center"
                                      onClick={() => handleDownload(datasetResponse?.datasetRealId, datasetResponse?.dataFlowType)}
                                      disabled={isDownloading}
                                    >
                                      {isDownloading ? (
                                        <>
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                          />
                                          Downloading...
                                        </>
                                      ) : (
                                        <>
                                          <i
                                            className="bi bi-download me-2"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Download
                                        </>
                                      )}
                                    </button>
                                    <button type="button" className="btn btn-outline-primary border-0 rounded-0 px-2 px-lg-3" onClick={()=>{handleCartToggle(datasetResponse)}} disabled={isDownloading}>
                                    <i className={isInCart ? "bi bi-bag-check-fill" : "bi bi-bag-plus"} aria-hidden="true"></i>
                                    {isInCart ? " IN CART" : " ADD TO CART"}</button>
                                </div>
                                
                                <div className="d-flex justify-content-end align-items-center">
                                    <div className="SRInfo py-2 px-2 px-lg-3 text-info-emphasis" data-bs-toggle="tooltip" data-bs-title="Information in this dataset was received with a qualified electronic seal. Date / time when the data were checked as valid for the last time by ESAP">
                                        <span className="visually-hidden">Date / time when the data were checked as valid for the last time by ESAP:</span>
                                        <i className="bi bi-patch-check-fill me-1" aria-hidden="true"></i> 23/01/2024 <small className="d-none d-md-inline">12:34</small>
                                    </div>
                                    
                                    <div className="SRInfo py-2 px-2 px-lg-3 text-info-emphasis" data-bs-toggle="tooltip" data-bs-title="Information in this dataset relates to historical data which pre-dates ESAP">
                                        <span className="visually-hidden">Information in this dataset relates to historical data which pre-dates ESAP</span>
                                        <i className="bi bi-clock-history" aria-hidden="true"></i>
                                    </div>
                                    
                                    <div className="SRInfo py-2 px-2 px-lg-3 text-info-emphasis" data-bs-toggle="tooltip" data-bs-title="Information in this dataset is voluntary">
                                        <span className="visually-hidden">Information in this dataset is voluntary</span>
                                        <i className="bi bi-gift-fill" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                            <dl className="DList DList-Compact DList-Separators DList-6-6 DL2Cols mt-4">
                            <dt>DATASET ID:</dt>
                                    <dd>{datasetResponse?.datasetRealId}</dd>
                                <dt>TYPE:</dt>
                                    <dd>{datasetResponse?.type}</dd>
                                <dt>PUBLISHER:</dt>
                                    <dd>{datasetResponse?.publisher}</dd>
                                <dt>COUNTRY:</dt>
                                    <dd>{datasetResponse?.country}</dd>
                            </dl>
                        </div>
                    </div>
                    
                </div>
            </div>
           

            
            <div className="py-5">
                <div className="container">
                    
                <div className="row">
                        <div className="col-12 col-lg-4">
                        
                            {/* Hidden div */}
                            <div className="card border-0 bg-transparent d-none mb-5">
                                <div className="card-header bg-transparent border-0"><h2 className="fs-6">Dataset Documents</h2></div>
                                <ul className="list-group list-group-flush border-top border-bottom">

                                    {datasetResponse.files.map((file: File) => (
                                        <li key={file.fileId} onClick={() => handleClick(file)} className="list-group-item d-flex justify-content-between align-items-center">
                                            <button>{ file.fileName }</button>
                                            <div className="btn-group" role="group" aria-label="Document actions">
                                                <button type="button" className="btn btn-outline-light link-primary border-0" data-bs-toggle="tooltip" data-bs-title="View"><i className="bi bi-eye-fill" aria-hidden="true"></i></button>
                                                <button type="button" className="btn btn-outline-light link-primary border-0" data-bs-toggle="tooltip" data-bs-title="Download"><i className="bi bi-download" aria-hidden="true" ></i></button>
                                                <button type="button" className="btn btn-outline-light link-primary border-0" data-bs-toggle="tooltip" data-bs-title="Translate"><i className="bi bi-translate" aria-hidden="true"></i></button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            
                            {/* Visible Dataset Documents */}
                            <h2 className="fs-6 m-0 py-2 px-3">Dataset Documents</h2>
                            <div className="list-group list-group-flush border-top border-bottom DocList">
                                {datasetResponse.files.map((file: File) => (
                                    <button key={file.fileId} onClick={() => handleClick(file)} className={'list-group-item list-group-item-action ' + (selectedFile?.fileId===file.fileId ? 'fw-bold' : '')}>
                                        <i className={(selectedFile?.fileId===file.fileId ? 'bi bi-file-text me-2' : '')} aria-hidden="true"></i>{ file.displayName }</button>
                                ))}
                            </div>
                        
                        </div>
                        
                        
                        {/* File Content */}
                        <div className="col-lg-8">
                            <h2 className="fs-5 fw-normal text-primary m-0 pt-1 pb-2 px-3"><i className="bi bi-file-text me-2" aria-hidden="true"></i> {selectedFile?.displayName}</h2>
                            
                            <div className="d-flex justify-content-end bg-primary rounded-1">
                                <button
                                      type="button"
                                      className="btn btn-primary border-0 rounded-0 px-2 px-lg-3 d-flex align-items-center"
                                      onClick={() => handleDatasetFileDownload(datasetResponse?.datasetRealId, selectedFile)}
                                      disabled={isFileDownloading || contentError !== null}
                                    >
                                      {isFileDownloading ? (
                                        <>
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                          />
                                          Downloading...
                                        </>
                                      ) : (
                                        <>
                                          <i
                                            className="bi bi-download me-2"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Download
                                        </>
                                      )}
                                    </button>
                                <button type="button" className="btn btn-primary border-0 rounded-0 px-2 px-lg-3" disabled={isFileDownloading || contentError !== null}>
                                    <i className="bi bi-translate me-2" aria-hidden="true"></i> Translate</button>
                            </div>
                            
                            <div className="bg-white border rounded-1 mt-3" style={{ height: 'auto', maxHeight: '900px', padding: '20px', overflow: 'auto', textAlign: 'left' }}>
                                {contentError && <div style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{contentError}</div>}

                                {loading && !contentError ? (
                                    <CustomSpinner />
                                ) : (
                                    selectedFile && fileContent && (
                                        <div>
                                            {selectedFile.fileType === FileTypes.XML && <XmlDisplay fileContent={fileContent} convertedToJson={datasetResponse.dataFlowType === DataFlowTypes.DATSSR} />}
                                            {selectedFile.fileType === FileTypes.XBRL && <XbrlDisplay fileContent={fileContent as string} />}
                                            {selectedFile.fileType === FileTypes.ESEF_ZIP && <EsefZipDisplay fileContent={fileContent as string} />}
                                            {selectedFile.fileType === FileTypes.CSV && <CsvDisplay fileContent={Array.isArray(fileContent) ? fileContent : []} />}
                                            {selectedFile.fileType === FileTypes.TXT && <PlainTextDisplay fileContent={fileContent as string} />}
                                            {selectedFile.fileType === FileTypes.PDF && <PdfDisplay fileContent={fileContent as Blob} />}
                                            {selectedFile.fileType === FileTypes.JSON && <JsonDisplay fileContent={fileContent as object} />}
                                            {(selectedFile.fileType === FileTypes.HTML || selectedFile.fileType === FileTypes.XHTML) && <HtmlDisplay fileContent={fileContent as string} />}
                                        </div>
                                    )
                                )}
                            </div>
                                
                        </div>
                        
                    </div>

                </div>
            </div>
        </Stack>
    );
}

export default DatasetDetails;
