import React, {useEffect, useState} from "react";
import {UserInfoContext} from "./UserInfoContext";
import {getUserInfo} from "../services/UserInfoService/UserInfoService";
import {UserInfo} from "../model/UserInfo";
import accessTokenService from "../services/AccessTokenService/AccessTokenService.ts";
import { useAuth} from "react-oidc-context";
import {LS_ACCESS_TOKEN, Roles} from "../constants/EsapConstants.ts";

export const UserInfoProvider = ({children}: { children: React.ReactNode }) => {
    const [user, setUser] = useState<UserInfo | null>(null);
    const auth = useAuth();

    useEffect(() => {

        const fetchUserAuthInfo = async () => {

            if(!auth || auth.activeNavigator || auth.isLoading){
                return;
            }

            const checkUserInfoUrl = import.meta.env.VITE_REACT_APP_USERINFO_URL;
            // const checkUserInfoUrl = "http://localhost:35020/esap-openid/userinfo";

            // GET user info
            const response = await fetch(checkUserInfoUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                return data;
            }
        }

        fetchUserAuthInfo().then((data) => {
            if (data && data.roles && auth && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                accessTokenService.clearOidcTokens();
                // if (data.roles.includes("administrator")) {
                //     auth.signinRedirect();
                // } else{
                //     auth.signinSilent();
                // }
                auth.signinRedirect();
            } else if (data && data.message && auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                auth.signoutSilent();
            }
        });

    }, [auth]);

    useEffect(() => {
        if (auth.user && auth.user.id_token && auth.isAuthenticated) {
            const userInfo = {...getUserInfo(auth.user.id_token), isAuthenticated: auth.isAuthenticated};
            accessTokenService.setAccessToken({[LS_ACCESS_TOKEN]: auth.user.access_token});
            setUser(userInfo);
        } else {
            // TODO: check flag associated with webPortalRole
            // empty user default to webPortalRole
            setUser({name: "", username: "", roles: [Roles.WEB_PORTAL_ROLE], picture: "", email: ""});
        }
    }, [auth]);

    return (
        <UserInfoContext.Provider value={{user, setUser}}>
            {children}
        </UserInfoContext.Provider>
    );
};
