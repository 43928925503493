import React, { useEffect, useState } from "react";
import { TranslationContext } from "./TranslationContext";
import { initTranslations } from "../config/i18nConfig";

export const TranslationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isTranslationsReady, setIsTranslationsReady] = useState(false);

    useEffect(() => {
        (async () => {
            await initTranslations();
            setIsTranslationsReady(true);
        })();
    }, []);

    if (!isTranslationsReady) {
        return <></>;
    }

    return (
        <TranslationContext.Provider value={{ isTranslationsReady }}>
            {children}
        </TranslationContext.Provider>
    );
};
