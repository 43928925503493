import {UserInfo} from "../../model/UserInfo.ts";
import ServiceEndpoints from "../ServiceEndpoints.ts";
import {Roles} from "../../constants/EsapConstants.ts";

export function getUserInfo(token: string | null): UserInfo {
    const userInfo: UserInfo = {name: "", username: "", roles: [], picture: "", email: ""};
    if (token !== null) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const decodedJWT = JSON.parse(window.atob(base64));
        userInfo.name = decodedJWT.name
        userInfo.email = decodedJWT.email
        userInfo.roles = getUserRoles();
    }
    return userInfo;
}

export const getUserRoles = () => {
    // return [];
    return [Roles.WEB_PORTAL_ROLE, Roles.SEARCH_ENGINE_ROLE, Roles.DOWNLOAD_SERVICE_ROLE, Roles.INFORMATION_VIEWER_ROLE, Roles.TRANSLATION_ROLE, Roles.NOTIFICATION_ROLE, Roles.DATA_FLOW_ROLE, Roles.ESAP_SUPPORT_ROLE, Roles.ESAP_DATA_MANAGER_ROLE, Roles.ESAP_DATA_ADMINISTRATOR_ROLE];
};

// export async function fetchUserInfo(token: string | undefined) {
//     return await fetch("https://graph.microsoft.com/oidc/userinfo",
//         { method: 'get', headers: { "Authorization": "Bearer "+token } })
//         .then(async (resp) => {
//             if(resp.ok){
//                 const parsedData: UserInfo = await resp.json();
//                 return parsedData;
//             } else {
//                 return undefined;
//             }
//         }).catch((error) => {
//             console.error(error);
//             return undefined;
//         });
// }

export async function fetchUserPhoto(token: string | null | undefined)  {
    return await fetch(ServiceEndpoints.FetchUserPhoto,
        { method: 'get', headers: { "Authorization": "Bearer "+token } })
        .then(async (resp) => {
            if(resp.ok){
                return resp.blob()
            } else {
                return undefined;
            }
        }).then((blob) => {
            if(blob) {
                return blob;
            } else {
                return undefined;
            }
        }).catch((error) => {
            console.error(error);
            return undefined;
        });
}