export const LS_USER_PHOTO: string = "USER_PHOTO";
export const CK_LANGUAGE: string = "LANGUAGE";
export const LS_ACCESS_TOKEN: string = "accessToken";
export const LS_ID_TOKEN: string = "idToken";
export const LS_LOGOUT_TOKEN: string = "logoutToken";

export const DEFAULT_LANGUAGE: string = "en";
export const FALLBACK_LANGUAGE: string = "en";

// Labels
// HEADER
export const ABOUT_LABEL: string = "UI_ABOUT_LABEL";
export const DOCUMENTATION_AND_SUPPORT_LABEL: string = "UI_DOCUMENTATION_AND_SUPPORT_LABEL";
export const FEEDBACK_LABEL: string = "UI_FEEDBACK_LABEL";
export const SUBMISSIONS_LABEL: string = "UI_SUBMISSIONS_LABEL";
export const REPORTING_ENTITIES_LABEL: string = "UI_REPORTING_ENTITIES_LABEL";
export const FILE_UPLOAD_LABEL_AND_HISTORY_OF_SUBMISSIONS_LABEL: string = "UI_FILE_UPLOAD_LABEL_AND_HISTORY_OF_SUBMISSIONS_LABEL";
export const ACCOUNT_PREFERENCES_LABEL: string = "UI_ACCOUNT_PREFERENCES_LABEL";
export const USER_ROLE_LABEL: string = "UI_USER_ROLE_LABEL";
export const LOGIN_LABEL: string = "UI_LOGIN_LABEL";
export const LOGIN_WITH_ENTRA_ID_LABEL: string = "UI_LOGIN_WITH_ENTRA_ID_LABEL";
export const LOGOUT_LABEL: string = "UI_LOGOUT_LABEL";
export const SYSTEM_ADMINISTRATION_LABEL: string = "UI_SYSTEM_ADMINISTRATION_LABEL";
export const TEST_SUBMISSION_OF_ESAP_INFO_LABEL: string = "UI_TEST_SUBMISSION_OF_ESAP_INFO_LABEL";
export const MANAGE_ONLINE_HELP_AND_TUTORIALS: string = "UI_MANAGE_ONLINE_HELP_AND_TUTORIALS";
export const MANAGE_PUBLIC_REFERENCE_DOCUMENTATION: string = "UI_MANAGE_PUBLIC_REFERENCE_DOCUMENTATION";
export const FILE_RESUBMISSION: string = "UI_FILE_RESUBMISSION";
export const ELEMENTS_VISIBILITY_CONTROL: string = "UI_ELEMENTS_VISIBILITY_CONTROL";
export const NOTIFICATION_MANAGEMENT: string = "UI_NOTIFICATION_MANAGEMENT";
export const UPDATE_ON_USERS_REQUESTS: string = "UI_UPDATE_ON_USERS_REQUESTS";
export const SYSTEM_MONITORING_LABEL: string = "UI_SYSTEM_MONITORING_LABEL";
export const MONITORING_REPORT_RESULTS_LABEL: string = "UI_MONITORING_REPORT_RESULTS_LABEL";
export const DATA_QUALITY_AND_MONITORING_LABEL: string = "UI_DATA_QUALITY_AND_MONITORING_LABEL";
export const DASHBOARDS_LABEL: string = "UI_DASHBOARDS_LABEL";
export const DATA_PUBLICATION_STATUS_LABEL: string = "UI_DATA_PUBLICATION_STATUS_LABEL";

// SEARCH
export const SEARCH_HEADER_TEXT: string = "UI_SEARCH_HEADER_TEXT";
export const SEARCH_PLACEHOLDER_TEXT: string = "UI_SEARCH_PLACEHOLDER_TEXT";
export const SEARCH_TOOLTIP_TEXT: string = "UI_SEARCH_TOOLTIP_TEXT";
export const SEARCH_FILTERS_TEXT: string = "UI_SEARCH_FILTERS_TEXT";
export const SEARCH_MORE_FILTERS_TEXT: string = "UI_SEARCH_MORE_FILTERS_TEXT";
export const SEARCH_BUTTON_TEXT: string = "UI_SEARCH_BUTTON_TEXT";
export const SEARCH_CLEAR_BUTTON_TEXT: string = "UI_CLEAR_BUTTON_TEXT";

// Filters
export const SEARCH_FILTERS_TEST_LABEL: string = "UI_SEARCH_FILTERS_TEST_LABEL";
export const SEARCH_FILTERS_TEST_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_TEST_TOOLTIP_TEXT";
export const SEARCH_FILTERS_DATE_RANGE_LABEL: string = "UI_SEARCH_FILTERS_DATE_RANGE_LABEL";
export const SEARCH_FILTERS_DATE_RANGE_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_DATE_RANGE_TOOLTIP_TEXT";
export const SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL: string = "UI_SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL";
export const SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL_TOOLTIP_TEXT";
export const SEARCH_FILTERS_FREE_TEXT_LABEL: string = "UI_SEARCH_FILTERS_FREE_TEXT_LABEL";
export const SEARCH_FILTERS_FREE_TEXT_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_FREE_TEXT_TOOLTIP_TEXT";
export const SEARCH_FILTERS_DATE_RANGE_FROM_PLACEHOLDER: string = "UI_SEARCH_FILTERS_DATE_RANGE_FROM_PLACEHOLDER";
export const SEARCH_FILTERS_DATE_RANGE_TO_PLACEHOLDER: string = "UI_SEARCH_FILTERS_DATE_RANGE_TO_PLACEHOLDER";
export const SEARCH_FILTERS_ENTITY_LEI: string = "UI_SEARCH_FILTERS_ENTITY_LEI";
export const SEARCH_FILTERS_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_ENTITY_LEI_TOOLTIP_TEXT";
export const SEARCH_FILTERS_ENTITY_NAME: string = "UI_SEARCH_FILTERS_ENTITY_NAME";
export const SEARCH_FILTERS_ENTITY_NAME_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_ENTITY_NAME_TOOLTIP_TEXT";
export const SEARCH_FILTERS_SIZE_OF_ENTITY: string = "UI_SEARCH_FILTERS_SIZE_OF_ENTITY";
export const SEARCH_FILTERS_SIZE_OF_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_SIZE_OF_ENTITY_TOOLTIP_TEXT";
export const SEARCH_FILTERS_SUBMISSION_DATE: string = "UI_SEARCH_FILTERS_SUBMISSION_DATE";
export const SEARCH_FILTERS_SUBMISSION_DATE_FROM: string = "UI_SEARCH_FILTERS_SUBMISSION_DATE_FROM";
export const SEARCH_FILTERS_SUBMISSION_DATE_TO: string = "UI_SEARCH_FILTERS_SUBMISSION_DATE_TO";
export const SEARCH_FILTERS_SUBMISSION_DATE_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_SUBMISSION_DATE_TOOLTIP_TEXT";
export const SEARCH_FILTERS_DATE_COVERED: string = "UI_SEARCH_FILTERS_DATE_COVERED";
export const SEARCH_FILTERS_DATE_COVERED_FROM: string = "UI_SEARCH_FILTERS_DATE_COVERED_FROM";
export const SEARCH_FILTERS_DATE_COVERED_TO: string = "UI_SEARCH_FILTERS_DATE_COVERED_TO";
export const SEARCH_FILTERS_DATE_COVERED_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_DATE_COVERED_TOOLTIP_TEXT";
export const SEARCH_FILTERS_SUBMITTING_ENTITY_NAME: string = "UI_SEARCH_FILTERS_SUBMITTING_ENTITY_NAME";
export const SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE: string = "UI_SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE";
export const SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE_TOOLTIP_TEXT";
export const SEARCH_FILTERS_ORIGINAL_LANGUAGE: string = "UI_SEARCH_FILTERS_ORIGINAL_LANGUAGE";
export const SEARCH_FILTERS_ORIGINAL_LANGUAGE_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_ORIGINAL_LANGUAGE_TOOLTIP_TEXT";
export const SEARCH_SUBMITTING_ENTITY_NAME: string = "UI_SEARCH_SUBMITTING_ENTITY_NAME";
export const SEARCH_SUBMITTING_ENTITY_NAME_TOOLTIP_TEXT: string = "UI_SEARCH_SUBMITTING_ENTITY_NAME_TOOLTIP_TEXT";
export const SEARCH_SUBMITTING_ENTITY_LEI: string = "UI_SEARCH_SUBMITTING_ENTITY_LEI";
export const SEARCH_SUBMITTING_ENTITY_LEI_TOOLTIP_TEXT: string = "UI_SEARCH_SUBMITTING_ENTITY_LEI_TOOLTIP_TEXT";
export const SEARCH_SIZE_OF_SUBMITTING_ENTITY: string = "UI_SEARCH_SIZE_OF_SUBMITTING_ENTITY";
export const SEARCH_SIZE_OF_SUBMITTING_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_SIZE_OF_SUBMITTING_ENTITY_TOOLTIP_TEXT";
export const SEARCH_LEI_STATUS: string = "UI_SEARCH_LEI_STATUS";
export const SEARCH_LEI_STATUS_TOOLTIP_TEXT: string = "UI_SEARCH_LEI_STATUS_TOOLTIP_TEXT";
export const SEARCH_INDUSTRY_SECTOR: string = "UI_SEARCH_INDUSTRY_SECTOR";
export const SEARCH_INDUSTRY_SECTOR_TOOLTIP_TEXT: string = "UI_SEARCH_INDUSTRY_SECTOR_TOOLTIP_TEXT";
export const SEARCH_LEGAL_FRAMEWORK: string = "UI_SEARCH_LEGAL_FRAMEWORK";
export const SEARCH_LEGAL_FRAMEWORK_TOOLTIP_TEXT: string = "UI_SEARCH_LEGAL_FRAMEWORK_TOOLTIP_TEXT";
export const SEARCH_COLLECTION_BODY_NAME: string = "UI_SEARCH_COLLECTION_BODY_NAME";
export const SEARCH_COLLECTION_BODY_NAME_TOOLTIP_TEXT: string = "UI_SEARCH_COLLECTION_BODY_NAME_TOOLTIP_TEXT";
export const SEARCH_HOME_MEMBER_STATE_OF_ENTITY: string = "UI_SEARCH_HOME_MEMBER_STATE_OF_ENTITY";
export const SEARCH_HOME_MEMBER_STATE_OF_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_HOME_MEMBER_STATE_OF_ENTITY_TOOLTIP_TEXT";
export const SEARCH_HOST_MEMBER_STATE_OF_ENTITY: string = "UI_SEARCH_HOST_MEMBER_STATE_OF_ENTITY";
export const SEARCH_HOST_MEMBER_STATE_OF_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_HOST_MEMBER_STATE_OF_ENTITY_TOOLTIP_TEXT";
export const SEARCH_ISIN: string = "UI_SEARCH_ISIN";
export const SEARCH_ISIN_TOOLTIP_TEXT: string = "UI_SEARCH_ISIN_TOOLTIP_TEXT";
export const SEARCH_FISN: string = "UI_SEARCH_FISN";
export const SEARCH_FISN_TOOLTIP_TEXT = "UI_SEARCH_FISN_TOOLTIP_TEXT";
export const SEARCH_VOLUNTARY_INFO_FLAG: string = "UI_SEARCH_VOLUNTARY_INFO_FLAG";
export const SEARCH_VOLUNTARY_INFO_FLAG_TOOLTIP_TEXT: string = "UI_SEARCH_VOLUNTARY_INFO_FLAG_TOOLTIP_TEXT";
export const SEARCH_HISTORICAL_INFO_FLAG: string = "UI_SEARCH_HISTORICAL_INFO_FLAG";
export const SEARCH_HISTORICAL_INFO_FLAG_TOOLTIP_TEXT: string = "UI_SEARCH_HISTORICAL_INFO_FLAG_TOOLTIP_TEXT";
export const SEARCH_NO_RESULTS: string = "UI_SEARCH_NO_RESULTS";




export const FILTER_KEY_MAPPING: { [key: string]: string } = {
    "Test": SEARCH_FILTERS_TEST_LABEL,
    "Type of information": SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL,
    "date range": SEARCH_FILTERS_DATE_RANGE_LABEL,
    "test text long": SEARCH_FILTERS_FREE_TEXT_LABEL,
    "Entity LEI": SEARCH_FILTERS_ENTITY_LEI,
    "Entity name": SEARCH_FILTERS_ENTITY_NAME,
    "Size of the Entity": SEARCH_FILTERS_SIZE_OF_ENTITY,
    "Submission date / time": SEARCH_FILTERS_SUBMISSION_DATE,
    "Date or period covered": SEARCH_FILTERS_DATE_COVERED,
    "Country of registered office": SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE,
    "Original language(s)": SEARCH_FILTERS_ORIGINAL_LANGUAGE,
    "Submitting entity name": SEARCH_SUBMITTING_ENTITY_NAME,
    "Submitting entity LEI": SEARCH_SUBMITTING_ENTITY_LEI,
    "Size of the submitting entity": SEARCH_SIZE_OF_SUBMITTING_ENTITY,
    "LEI status": SEARCH_LEI_STATUS,
    "Industry sector(s)": SEARCH_INDUSTRY_SECTOR,
    "Legal framework": SEARCH_LEGAL_FRAMEWORK,
    "Collection Body name": SEARCH_COLLECTION_BODY_NAME,
    "Home member state of the entity concerned": SEARCH_HOME_MEMBER_STATE_OF_ENTITY,
    "Host member state of the entity concerned": SEARCH_HOST_MEMBER_STATE_OF_ENTITY,
    "ISIN": SEARCH_ISIN,
    "FISN": SEARCH_FISN,
    "Voluntary information flag": SEARCH_VOLUNTARY_INFO_FLAG,
    "Historical information flag": SEARCH_HISTORICAL_INFO_FLAG
};


// DYNAMIC ALERTS
export const SEARCH_DYNAMIC_ALERTS_FREE_TEXT: string = "UI_SEARCH_DYNAMIC_ALERTS_FREE_TEXT";
export const SEARCH_DYNAMIC_ALERTS_TYPE_OF_INFORMATION: string = "UI_SEARCH_DYNAMIC_ALERTS_TYPE_OF_INFORMATION";
export const SEARCH_DYNAMIC_ALERTS_ENTITY_LEI: string = "UI_SEARCH_DYNAMIC_ALERTS_ENTITY_LEI";
export const SEARCH_DYNAMIC_ALERTS_ENTITY_NAME: string = "UI_SEARCH_DYNAMIC_ALERTS_ENTITY_NAME";
export const SEARCH_DYNAMIC_ALERTS_SIZE_OF_ENTITY: string = "UI_SEARCH_DYNAMIC_ALERTS_SIZE_OF_ENTITY";
export const SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_FROM: string = "UI_SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_FROM";
export const SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_TO: string = "UI_SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_TO";
export const SEARCH_DYNAMIC_ALERTS_DATE_COVERED_FROM: string = "UI_SEARCH_DYNAMIC_ALERTS_DATE_COVERED_FROM";
export const SEARCH_DYNAMIC_ALERTS_DATE_COVERED_TO: string = "UI_SEARCH_DYNAMIC_ALERTS_DATE_COVERED_TO";
export const SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_NAME: string = "UI_SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_NAME";
export const SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_LEI: string = "UI_SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_LEI";
export const SEARCH_DYNAMIC_ALERTS_SIZE_OF_SUBMITTING_ENTITY: string = "UI_SEARCH_DYNAMIC_ALERTS_SIZE_OF_SUBMITTING_ENTITY";
export const SEARCH_DYNAMIC_ALERTS_LEI_STATUS: string = "UI_SEARCH_DYNAMIC_ALERTS_LEI_STATUS";
export const SEARCH_DYNAMIC_ALERTS_COUNTRY_OF_REGISTERED_OFFICE: string = "UI_SEARCH_DYNAMIC_ALERTS_COUNTRY_OF_REGISTERED_OFFICE";
export const SEARCH_DYNAMIC_ALERTS_INDUSTRY_SECTOR: string = "UI_SEARCH_DYNAMIC_ALERTS_INDUSTRY_SECTOR";
export const SEARCH_DYNAMIC_ALERTS_ORIGINAL_LANGUAGE: string = "UI_SEARCH_DYNAMIC_ALERTS_ORIGINAL_LANGUAGE";
export const SEARCH_DYNAMIC_ALERTS_LEGAL_FRAMEWORK: string = "UI_SEARCH_DYNAMIC_ALERTS_LEGAL_FRAMEWORK";
export const SEARCH_DYNAMIC_ALERTS_COLLECTION_BODY_NAME: string = "UI_SEARCH_DYNAMIC_ALERTS_COLLECTION_BODY_NAME";
export const SEARCH_DYNAMIC_ALERTS_HOME_MEMBER_STATE_OF_ENTITY: string = "UI_SEARCH_DYNAMIC_ALERTS_HOME_MEMBER_STATE_OF_ENTITY";
export const SEARCH_DYNAMIC_ALERTS_HOST_MEMBER_STATE_OF_ENTITY: string = "UI_SEARCH_DYNAMIC_ALERTS_HOST_MEMBER_STATE_OF_ENTITY";
export const SEARCH_DYNAMIC_ALERTS_ISIN: string = "UI_SEARCH_DYNAMIC_ALERTS_ISIN";
export const SEARCH_DYNAMIC_ALERTS_FISN: string = "UI_SEARCH_DYNAMIC_ALERTS_FISN";
export const SEARCH_DYNAMIC_ALERTS_VOLUNTARY_INFO_FLAG: string = "UI_SEARCH_DYNAMIC_ALERTS_VOLUNTARY_INFO_FLAG";
export const SEARCH_DYNAMIC_ALERTS_HISTORICAL_INFO_FLAG: string = "UI_SEARCH_DYNAMIC_ALERTS_HISTORICAL_INFO_FLAG";



export const DYNAMIC_ALERT_KEYS: { [key: string]: string } = {
    freeText: SEARCH_DYNAMIC_ALERTS_FREE_TEXT,
    typeOfInformation: SEARCH_DYNAMIC_ALERTS_TYPE_OF_INFORMATION,
    entityLEI: SEARCH_DYNAMIC_ALERTS_ENTITY_LEI,
    entityName: SEARCH_DYNAMIC_ALERTS_ENTITY_NAME,
    sizeOfEntity: SEARCH_DYNAMIC_ALERTS_SIZE_OF_ENTITY,
    submissionDateFrom: SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_FROM,
    submissionDateTo: SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_TO,
    dateCoveredFrom: SEARCH_DYNAMIC_ALERTS_DATE_COVERED_FROM,
    dateCoveredTo: SEARCH_DYNAMIC_ALERTS_DATE_COVERED_TO,
    submittingEntityName: SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_NAME,
    submittingEntityLEI: SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_LEI,
    sizeOfSubmittingEntity: SEARCH_DYNAMIC_ALERTS_SIZE_OF_SUBMITTING_ENTITY,
    leiStatus: SEARCH_DYNAMIC_ALERTS_LEI_STATUS,
    countryOfRegisteredOffice: SEARCH_DYNAMIC_ALERTS_COUNTRY_OF_REGISTERED_OFFICE,
    industrySector: SEARCH_DYNAMIC_ALERTS_INDUSTRY_SECTOR,
    originalLanguage: SEARCH_DYNAMIC_ALERTS_ORIGINAL_LANGUAGE,
    legalFramework: SEARCH_DYNAMIC_ALERTS_LEGAL_FRAMEWORK,
    collectionBodyName: SEARCH_DYNAMIC_ALERTS_COLLECTION_BODY_NAME,
    homeMemberStateOfEntity: SEARCH_DYNAMIC_ALERTS_HOME_MEMBER_STATE_OF_ENTITY,
    hostMemberStateOfEntity: SEARCH_DYNAMIC_ALERTS_HOST_MEMBER_STATE_OF_ENTITY,
    isin: SEARCH_DYNAMIC_ALERTS_ISIN,
    fisn: SEARCH_DYNAMIC_ALERTS_FISN,
    voluntaryInfoFlag: SEARCH_DYNAMIC_ALERTS_VOLUNTARY_INFO_FLAG,
    historicalInfoFlag: SEARCH_DYNAMIC_ALERTS_HISTORICAL_INFO_FLAG
};

// SEARCH RESULTS
export const SEARCH_RESULTS_PAGING_OF = "UI_SEARCH_RESULTS_PAGING_OF";
export const SEARCH_RESULTS = "UI_SEARCH_RESULTS_PAGING_ITEMS_RESULTS";
export const SEARCH_RESULTS_SORT_BY = "UI_SEARCH_RESULTS_SORT_BY";
export const SEARCH_RESULTS_SORT_BY_RELEVANCE = "UI_SEARCH_RESULTS_SORT_BY_RELEVANCE";
export const SEARCH_RESULTS_SORT_BY_SUBMISSION_DATE = "UI_SEARCH_RESULTS_SORT_BY_SUBMISSION_DATE";
export const SEARCH_RESULTS_SORT_BY_PERIOD_COVERED = "UI_SEARCH_RESULTS_SORT_BY_PERIOD_COVERED";
export const SEARCH_RESULTS_SORT_BY_INFO_TYPE = "UI_SEARCH_RESULTS_SORT_BY_INFO_TYPE";
export const SEARCH_RESULTS_SHOW = "UI_SEARCH_RESULTS_SHOW";
export const SEARCH_RESULTS_DOWNLOAD_DATA_SET = "UI_SEARCH_RESULTS_DOWNLOAD_DATA_SET";
export const SEARCH_RESULTS_ADD_DATA_SET_TO_CART = "UI_SEARCH_RESULTS_DOWNLOAD_ADD_DATA_SET_TO_CART";
export const SEARCH_RESULTS_REMOVE_DATA_SET_FROM_CART = "UI_SEARCH_RESULTS_DOWNLOAD_REMOVE_DATA_SET_FROM_CART";
export const SEARCH_RESULTS_ACCESS_INFO_VIEWER = "UI_SEARCH_RESULTS_ACCESS_INFO_VIEWER";
export const SEARCH_RESULTS_ELECTRONIC_SEAL = "UI_SEARCH_RESULTS_ELECTRONIC_SEAL";
export const SEARCH_RESULTS_INFO_RELATED_TO_HISTORICAL_DATA = "UI_SEARCH_RESULTS_INFO_RELATED_TO_HISTORICAL_DATA";
export const SEARCH_RESULTS_INFO_RELATED_TO_VOLUNTARY_DATA = "UI_SEARCH_RESULTS_INFO_RELATED_TO_VOLUNTARY_DATA";
export const SEARCH_RESULTS_PAGINATION_FOOTER_FIRST = "UI_SEARCH_RESULTS_PAGINATION_FOOTER_FIRST";
export const SEARCH_RESULTS_PAGINATION_FOOTER_PREVIOUS = "UI_SEARCH_RESULTS_PAGINATION_FOOTER_PREVIOUS";
export const SEARCH_RESULTS_PAGINATION_FOOTER_NEXT = "UI_SEARCH_RESULTS_PAGINATION_FOOTER_NEXT";
export const SEARCH_RESULTS_PAGINATION_FOOTER_LAST = "UI_SEARCH_RESULTS_PAGINATION_FOOTER_LAST";

// REPORTING ENTITIES
export const RE_HEADER_TEXT: string = "UI_RE_HEADER_TEXT";
export const RE_TYPE_OF_DATA_LABEL: string = "UI_RE_TYPE_OF_DATA_LABEL";
export const RE_TYPE_OF_DATA_TOOLTIP_TEXT: string = "UI_RE_TYPE_OF_DATA_TOOLTIP_TEXT";
export const RE_SYSTEM_VERSION_LABEL: string = "UI_RE_SYSTEM_VERSION_LABEL";
export const RE_SYSTEM_VERSION_TOOLTIP_TEXT: string = "UI_RE_SYSTEM_VERSION_TOOLTIP_TEXT";
export const RE_VERSION_RELATED_DOCUMENTATION_BUTTON_TEXT: string = "UI_RE_VERSION_RELATED_DOCUMENTATION_BUTTON_TEXT";
export const RE_TEST_DATA_LABEL: string = "UI_RE_TEST_DATA_LABEL";
export const RE_FILE_UPLOAD_TOOLTIP_TEXT: string = "UI_RE_FILE_UPLOAD_TOOLTIP_TEXT";
export const RE_FILE_UPLOAD_HELPER_TEXT: string = "UI_RE_FILE_UPLOAD_HELPER_TEXT";
export const RE_UPLOAD_TEST_DATA_BUTTON_TEXT: string = "UI_RE_UPLOAD_TEST_DATA_BUTTON_TEXT";
export const RE_UPLOADING_TEST_DATA_TEXT: string = "UI_RE_UPLOADING_TEST_DATA_TEXT";

// IMPORTANT ANNOUNCEMENTS
export const IMPORTANT_ANNOUNCEMENTS_LABEL: string = "UI_IMPORTANT_ANNOUNCEMENTS_LABEL";
export const IMPORTANT_ANNOUNCEMENTS_TEXT: string = "UI_IMPORTANT_ANNOUNCEMENTS_TEXT";

// FOOTER
export const DISCLAIMER_LABEL: string = "UI_DISCLAIMER_LABEL";
export const LEGAL_NOTICE_LABEL: string = "UI_LEGAL_NOTICE_LABEL";

export const Roles = {
    WEB_PORTAL_ROLE: 'WebPortalRole',
    SEARCH_ENGINE_ROLE: 'SearchEngineRole',
    DOWNLOAD_SERVICE_ROLE: 'DownloadServiceRole',
    INFORMATION_VIEWER_ROLE: 'InformationViewerRole',
    TRANSLATION_ROLE: 'TranslationRole',
    NOTIFICATION_ROLE: 'NotificationRole',
    DATA_FLOW_ROLE: 'DataFlowRole',
    ESAP_SUPPORT_ROLE: 'ESAPSupportRole',
    ESAP_DATA_MANAGER_ROLE: 'ESAPDataManagerRole',
    ESAP_DATA_ADMINISTRATOR_ROLE: 'ESAPDataAdministratorRole',
};

export const CustomEvents = {
    VIEW_DATASET: "viewDatasetEvent",
    DOWNLOAD_DATASET: "downloadDatasetEvent",
    VIEW_FILE: "viewFileEvent",
    DOWNLOAD_FILE: "downloadFileEvent"
}

export const DataFlowTypes = {
    DATPRI: "DATPRI",
    DATPXD: "DATPXD",
    DATTRD : "DATTRD",
    DATSSR : "DATSSR"
}